<template>
  <div class="simple-group">
    <div class="simple-group-header" @click="$emit('check', data.id)">
      <div class="title">
        <span class="name">{{ data.name }}</span>
        <div class="time">建群时间：{{ data.createTime }}</div>
      </div>
      <van-icon name="arrow" />
    </div>
    <div class="simple-group-subtitle">成员概况</div>
    <div class="simple-group-body">
      <div class="item">
        <span>今日入群：{{ data.enterNum }}</span>
      </div>
      <div class="item">
        <span>成员总数：{{ data.memberNum }}</span>
      </div>
      <div class="item">
        <span>今日退群：{{ data.leaveNum }}</span>
      </div>
      <div class="item">
        <span>待加好友：{{ data.waitAddNum }}</span>
      </div>
    </div>
    <van-button
      type="primary"
      size="small"
      round
      block
      @click="$emit('open', data.id)"
    >
      前往群聊
    </van-button>
  </div>
</template>

<script>
import { Button, Icon, Image } from 'vant'

export default {
  components: {
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Button.name]: Button
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="less" scope>
@import '../../assets/style/theme.less';

@padding: 12px;

.simple-group {
  margin: 8px;
  padding: @padding;
  border-radius: 5px;
  font-size: 14px;
  background: @white;

  &-header {
    display: flex;
    align-items: center;
    color: @gray-7;
    > .title {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    > .van-icon {
      font-weight: bold;
    }
  }

  &-subtitle {
    padding: 12px 0;
    color: @gray-7;
    line-height: 1.2;
  }

  &-body {
    display: flex;
    flex-wrap: wrap;
    color: @gray-6;
    > .item {
      display: inline-flex;
      align-items: center;
      line-height: 1.2;
      flex-basis: 50%;
      padding-bottom: 8px;
    }
  }
}

.van-icon-down.up {
  transform: rotate(180deg);
}
</style>
