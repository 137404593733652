<template>
  <!-- 顶部筛选条子 -->
  <wrapper @sort="handleSort" @search="handleSearch" ref="wrapper" />
  <!-- 客户群概览 -->
  <summarize />
  <!-- 下拉刷新 -->
  <van-pull-refresh
    style="min-height: 100%"
    v-model="refreshing"
    @refresh="handleRefresh"
  >
    <!-- 列表 -->
    <van-list
      v-model:loading="loading"
      v-model:error="error"
      :finished="finished"
      :finished-text="list.length == 0 ? '暂无数据' : '没有更多了'"
      error-text="请求失败，点击重新加载"
      @load="onNext"
    >
      <!-- 群信息 -->
      <simple-group
        v-for="item in list"
        :key="item.id"
        :data="item"
        @check="handleCheck"
        @open="handleOpen"
      />
    </van-list>
  </van-pull-refresh>
</template>

<script>
import { List, PullRefresh } from 'vant'
import { getGroup } from '../../service/group.service'
import { openEnterpriseChat, openExistedChatWithMsg } from '../../utils/nwechat'
import { pagination } from '../../utils/pagination'
import Wrapper from './_wrapper.vue'
import Summarize from './_summarize.vue'
import SimpleGroup from './_simple-group.vue'

export default {
  name: 'Community',
  components: {
    Wrapper,
    Summarize,
    SimpleGroup,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh
  },
  setup() {
    const {
      list,
      params,
      loading,
      finished,
      refreshing,
      error,
      onRefresh,
      onNext
    } = pagination(getGroup, { pageMode: true })

    return {
      list,
      params,
      loading,
      finished,
      refreshing,
      error,
      onRefresh,
      onNext
    }
  },
  methods: {
    // 手动进行刷新
    handleRefresh() {
      // 拿输入框的值，避免用户懵逼
      this.params.query = this.$refs.wrapper.query
      this.onRefresh()
    },
    // 点击排序
    handleSort({ order, orderType }) {
      this.params.order = order
      this.params.orderType = orderType
      this.onRefresh()
    },
    // 点击查询
    handleSearch(v) {
      this.params.query = v
      this.onRefresh()
    },
    // 点击查看-跳转到详情(我的客户)
    handleCheck(id) {
      this.$router.push({ name: 'community-detail-new', query: { id } })
    },
    // 打开群会话
    async handleOpen(id) {
      console.log('handleOpen')
      try {
        const data = await openExistedChatWithMsg({ chatId: id })
        console.log('openExistedChatWithMsg-try:', data)
      } catch (e) {
        console.log('openExistedChatWithMsg-catch:', e)
      }
    }
  }
}
</script>

<style lang="less" scope></style>
