<template>
  <div class="filter-control">
    <div class="title">{{ title }}</div>
    <div
      v-for="opt in options"
      :key="opt.id"
      class="item"
      @click="handleClick(opt)"
    >
      <span class="label">{{ opt.label }}</span>
      <van-icon v-if="opt.value === value" name="success" color="#409EFF" />
    </div>
  </div>
</template>

<script>
import { Icon } from 'vant'

export default {
  components: {
    [Icon.name]: Icon
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 标签列表
    options: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleClick(opt) {
      this.$emit('update:value', opt.value)
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/style/theme.less';

.filter-control {
  font-size: 14px;
  > .title {
    padding: 16px;
    font-weight: bold;
  }

  > .item {
    display: flex;
    margin: 0 16px;
    padding: 8px;
    border: thin solid @gray-3;
    border-bottom-width: 0;
    &:last-of-type {
      border-bottom-width: thin;
    }
    > .label {
      flex: 1;
      font-size: 12px;
    }
  }
}
</style>
